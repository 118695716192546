// import React, { useEffect, useState, useReducer } from "react";
// import {
//   Avatar,
//   Button,
//   CssBaseline,
//   TextField,
//   Link,
//   Grid,
//   Box,
//   Typography,
//   Container,
//   Paper,
//   CircularProgress,
// } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../store/AuthContext";
// import { loginapi } from "../services/api";
// import image from "../img/OPEN_NETWORK_FOR_DIGITAL_COMMERCE__ONDC_-removebg-preview.png";
// import { uploadFileToS3 } from "../Utils/s3Uploader";

// const initialState = {
//   data: null,
//   error: null,
//   loading: false,
// };

// function reducer(state, action) {
//   switch (action.type) {
//     case "REQUEST_LOGIN":
//       return { ...state, loading: true, error: null };
//     case "LOGIN_SUCCESS":
//       return { ...state, loading: false, data: action.payload, error: null };
//     case "LOGIN_ERROR":
//       return { ...state, loading: false, error: action.error };
//     default:
//       return state;
//   }
// }

// export default function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const { loading } = state;
//   const { login } = useAuth();
//   const navigate = useNavigate();
//   const theme = createTheme();

//   const [imageUploaded, setImageUploaded] = useState(false); 

//   const postData = async (e) => {
//     e.preventDefault();
//     dispatch({ type: "REQUEST_LOGIN" });
//     loginapi({ email, password, dispatch, login, navigate });
//   };

 
//   const uploadImageToS3 = async () => {
   
//     if (imageUploaded) return;

//     try {
   
//       await uploadFileToS3(image, "images", "image.png"); 
//       console.log("Image uploaded successfully");
//       setImageUploaded(true); 
//     } catch (err) {
//       console.error("Error uploading image:", err);
//     }
//   };

//   useEffect(() => {
    
//     if (!imageUploaded) {
//       uploadImageToS3(); 
//     }
//   }, [imageUploaded]); 

//   return (
//     <ThemeProvider theme={theme}>
//       <Box sx={{ display: "flex", minHeight: "100vh", flexDirection: { xs: "column", md: "row" } }}>
//         <Box sx={{ width: { xs: "100%", md: "60%" }, background: "#f1f0ff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 3 }}>
//           <Box sx={{ width: { xs: "80%", sm: "60%", md: "50%" } }}>
//             <img src={image} alt="Logo" style={{ width: "100%" }} loading="lazy" />
//           </Box>
//         </Box>

//         {/* Right Section */}
//         <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", background: "#d8d9ef", p: { xs: 3, md: 5 } }}>
//           <Container component="main" maxWidth="100%">
//             <CssBaseline />
//             <Paper elevation={6} sx={{ p: 4, borderRadius: "15px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fff" }}>
//               <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
//                 <LockOutlinedIcon />
//               </Avatar>
//               <Typography component="h1" variant="h5">Welcome to Codevik! 🌟</Typography>
//               <Typography variant="subtitle1" sx={{ textAlign: "center", mb: 3 }}>Please sign-in to your account and start the adventure</Typography>

//               <Box component="form" onSubmit={postData} noValidate sx={{ mt: 1 }}>
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   id="email"
//                   label="Email or Username"
//                   name="email"
//                   autoComplete="email"
//                   autoFocus
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   autoComplete="current-password"
//                   onChange={(e) => setPassword(e.target.value)}
//                 />

//                 <Button
//                   type="submit"
//                   fullWidth
//                   variant="contained"
//                   sx={{
//                     mt: 3,
//                     mb: 2,
//                     backgroundColor: "#7367f0",
//                     "&:hover": { backgroundColor: "#605ad0" },
//                     py: 1.2,
//                   }}
//                 >
//                   {loading ? <CircularProgress size={24} color="warning" /> : "Sign In"}
//                 </Button>

//                 <Grid container>
//                   <Grid item>
//                     <Link href="/multistepform" variant="body2" sx={{ color: "#7367f0" }}>
//                       {"Create an account"}
//                     </Link>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Paper>
//           </Container>
//         </Box>
//       </Box>
//     </ThemeProvider>
//   );
// }





































// import React, { useReducer, useState } from "react";
// import {
//   Avatar,
//   Button,
//   CssBaseline,
//   TextField,
//   FormControlLabel,
//   Checkbox,
//   Link,
//   Grid,
//   Box,
//   Typography,
//   Container,
//   Paper,
// } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { toast } from "react-toastify";
// import { useAuth } from "../store/AuthContext";

// // import Alertt from "../components/Alert/Alertt";
// import CircularProgress from "@mui/material/CircularProgress";
// import images from "../img/OPEN_NETWORK_FOR_DIGITAL_COMMERCE__ONDC_-removebg-preview.png";

// const initialState = {
//   data: null,
//   error: null,
//   loading: false,
// };

// function reducer(state, action) {
//   switch (action.type) {
//     case "REQUEST_LOGIN":
//       return {
//         ...state,
//         loading: true,
//         error: null,
//       };
//     case "LOGIN_SUCCESS":
//       return {
//         ...state,
//         loading: false,
//         data: action.payload,
//         error: null,
//       };
//     case "LOGIN_ERROR":
//       return {
//         ...state,
//         loading: false,
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }

// export default function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const { loading } = state;
//   const { login } = useAuth();
//   const navigate = useNavigate();
//   const theme = createTheme();

//   const postData = async (e) => {
//     e.preventDefault();
//     dispatch({ type: "REQUEST_LOGIN" });
//     try {
//       let response = await fetch(`http://localhost:8080/dashboard/adminLogin`, {
//         method: "post",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
//       response = await response.json();

//       if (response.success === true) {
//         dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
//         toast.success(response.message);
//         const token = response.token;
//         console.log(token);
        
//         Cookies.set("token", token, { expires: 7, secure: true });
//         login();
//         navigate("/dashboard");
//       } else {
//         toast.error(response.message);
//         dispatch({ type: "LOGIN_ERROR", error: response.message });
//       }
//     } catch (error) {
//       dispatch({ type: "LOGIN_ERROR", error: error.message });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Box sx={{ display: "flex", minHeight: "100vh" }}>
//         {/* Left Section */}
//         <Box
//           sx={{
//             width: "60%",
//             background: "#f1f0ff",
//             color: "black",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             p: 5,
//             backgroundSize: "cover", // Ensure the image covers the area
//             backgroundPosition: "center", // Center the image
//           }}
//         >
//           {/* <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
//             Impressive
//           </Typography>
//           <Typography variant="h4" sx={{ fontWeight: "medium" }}>
//             React Login Page Template
//           </Typography> */}
//           <Box>
//             <img src={images} alt="loading "/>
//           </Box>
//         </Box>

//         {/* Right Section */}
//         <Box
//           sx={{
//             flex: 1,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             background: "#d8d9ef",
//           }}
//         >
//           <Container component="main" maxWidth="xs">
//             <CssBaseline />
//             <Paper
//               elevation={6}
//               sx={{
//                 p: 4,
//                 borderRadius: "15px",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 backgroundColor: "#fff",
//                 marginLeft: "-17rem",
//                 marginRight: "13rem",
//               }}
//             >
//               {/* {error && (
//             <Alertt variant="filled" severity="error" position="top">
//               {error}
//             </Alertt>
//           )} */}

//               <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
//                 <LockOutlinedIcon />
//               </Avatar>
//               <Typography component="h1" variant="h5">
//                 Welcome to Codevik! 🌟
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 sx={{ textAlign: "center", mb: 3 }}
//               >
//                 Please sign-in to your account and start the adventure 
//               </Typography>
//               <Box
//                 component="form"
//                 onSubmit={postData}
//                 noValidate
//                 sx={{ mt: 1 }}
//               >
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   id="email"
//                   label="Email or Username"
//                   name="email"
//                   autoComplete="email"
//                   autoFocus
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   autoComplete="current-password"
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <FormControlLabel
//                   control={<Checkbox value="remember" color="primary" />}
//                   label="Remember me"
//                 />
//                 <Button
//                   type="submit"
//                   fullWidth
//                   variant="contained"
//                   sx={{
//                     mt: 3,
//                     mb: 2,
//                     backgroundColor: "#7367f0",
//                     "&:hover": {
//                       backgroundColor: "#605ad0",
//                     },
//                     py: 1.2,
//                   }}
//                 >
//                   {loading ? (
//                     <CircularProgress size={24} color="warning" />
//                   ) : (
//                     "Sign In"
//                   )}
//                 </Button>

//                 {/* <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 2 }}
//             disabled={loading}
//           >
//             {loading ? <CircularProgress size={24} color="warning" /> : "Login"}
//           </Button> */}
//                 <Grid container>
//                   <Grid item>
//                     <Link
//                       href="/multistepform"
//                       variant="body2"
//                       sx={{ color: "#7367f0" }}
//                     >
//                       {"New on our platform? Create an account"}
//                     </Link>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Paper>
//             <Box mt={5} textAlign="center">
            
//               {/* <Typography variant="body2" color="text.secondary">
//                 or sign in with
//               </Typography> */}
//               {/* <Box display="flex" justifyContent="center" mt={2}>
//                 <Link href="#" sx={{ mx: 1 }}>
//                   <img
//                     src="https://img.icons8.com/fluent/48/000000/facebook-new.png"
//                     alt="Facebook"
//                     style={{ width: 40, height: 40 }}
//                   />
//                 </Link>
//                 <Link href="#" sx={{ mx: 1 }}>
//                   <img
//                     src="https://img.icons8.com/color/48/000000/google-logo.png"
//                     alt="Google"
//                     style={{ width: 40, height: 40 }}
//                   />
//                 </Link>
//                 <Link href="#" sx={{ mx: 1 }}>
//                   <img
//                     src="https://img.icons8.com/fluent/48/000000/twitter.png"
//                     alt="Twitter"
//                     style={{ width: 40, height: 40 }}
//                   />
//                 </Link>
//               </Box> */}
//             </Box>
//           </Container>
//         </Box>
//       </Box>
//     </ThemeProvider>
//   );
// }












import React, { useReducer, useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  // FormControlLabel,
  // Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../store/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import image from "../img/OPEN_NETWORK_FOR_DIGITAL_COMMERCE__ONDC_-removebg-preview.png";
import { loginapi} from "../services/api"
// import { uploadFileToS3 } from "../../Utils/s3Uploader";


const initialState = {
  data: null,
  error: null,
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return { ...state, loading: true, error: null };
    case "LOGIN_SUCCESS":
      return { ...state, loading: false, data: action.payload, error: null };
    case "LOGIN_ERROR":
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading } = state;
  const { login } = useAuth();
  const navigate = useNavigate();
  const theme = createTheme();

  // const postData = async (e) => {
  //   e.preventDefault();
  //   dispatch({ type: "REQUEST_LOGIN" });
  //   try {
  //     let response = await fetch(`http://localhost:8080/dashboard/adminLogin`, {
  //       method: "post",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email, password }),
  //     });
  //     response = await response.json();

  //     if (response.success) {
  //       dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
  //       toast.success(response.message);
  //       Cookies.set("token", response.token, { expires: 7, secure: true });
  //       login();
  //       navigate("/dashboard");
  //     } else {
  //       toast.error(response.message);
  //       dispatch({ type: "LOGIN_ERROR", error: response.message });
  //     }
  //   } catch (error) {
  //     dispatch({ type: "LOGIN_ERROR", error: error.message });
  //   }
  // };

  const  postData = (e) => {
    e.preventDefault();
   loginapi({ email, password, dispatch, login, navigate });
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh", flexDirection: { xs: "column", md: "row" } }}>
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            background: "#f1f0ff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box sx={{ width: { xs: "80%", sm: "60%", md: "50%" } }}>
            <img src={image} alt="Logo" style={{ width: "100%" }} loading="lazy"  />
          </Box>
        </Box>

        {/* Right Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#d8d9ef",
            p: { xs: 3, md: 5 },
        
          }}
        >
          <Container component="main" maxWidth="100%">
            <CssBaseline />
            <Paper
              elevation={6}
              sx={{
                p: 4,
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#fff",
                mx: { xs: 0, md: "-15rem" },
                maxWidth:"100%"
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Welcome to Codevik! 🌟
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: "center", mb: 3 }}>
                Please sign-in to your account and start the adventure
              </Typography>
              <Box component="form" onSubmit={postData} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email or Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#7367f0",
                    "&:hover": { backgroundColor: "#605ad0" },
                    py: 1.2,
                  }}
                >
                  {loading ? <CircularProgress size={24} color="warning" /> : "Sign In"}
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/multistepform" variant="body2" sx={{ color: "#7367f0" }}>
                      {"Create an account"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
