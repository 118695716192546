import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Input,
  CircularProgress,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import { uploadFileToS3 } from "../../Utils/s3Uploader";
import { registerSellerAPI } from "../../services/api";
import image from "../../img/sellarbackgroundimg.webp";
const steps = [
  "Personal Information",
  "KYC Information",
  "Upload Documents",
  "Bank Details",
];

const MultiStepForm = () => {
  const navigate = useNavigate();
  const { sellerRegister } = useAuth();

  const [currentStep, setCurrentStep] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [previewUrls, setPreviewUrls] = useState({
    address: null,
    idProof: null,
    pan: null,
    gst: null,
    cancelledChequeURL: null,
  });
  const [formData, setFormData] = useState({
    detailsOfProvider: {
      email: "",
      mobileNumber: "",
      Orgname: "",
      password: "",
      role: "OrganizationAdmin",
      accessStatus: true,
      isApprovedByAdmin: true,
    },
    KYCdetails: {
      providerName: "",
      registeredAdd: "",
      storeEmail: "",
      mobileNo: "",
      PANNo: "",
      GSTIN: "",
      FSSAINo: "",
    },
    KYCurl: {
      address: null,
      idProof: null,
      pan: null,
      gst: null,
    },
    bankDetails: {
      accountHolderName: "",
      accountNo: "",
      bankName: "",
      branchName: "",
      ifscCode: "",
      cancelledChequeURL: null,
    },
  });

  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const { name, type, files, value } = e.target;
    const fieldName = name.split(".")[1];

    if (type === "file" && files.length > 0) {
      const file = files[0];

      if (!isValidFile(file)) {
        return;
      }

      const fileName = `${Date.now()}_${file.name}`;

      try {
        const data = await uploadFileToS3(file, fileName);
        updateFormData(name, data.Location);

        setPreviewUrls((prevState) => ({
          ...prevState,
          [fieldName]: URL.createObjectURL(file),
        }));

        toast.success("File uploaded successfully!");
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error(
          `Failed to upload file: ${error.message || "Unknown error"}`
        );
      }
    } else {
      updateFormData(name, value);
    }
  };

  const isValidFile = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 512 * 1024;

    if (!allowedTypes.includes(file.type)) {
      toast.error("Only JPEG, PNG, or PDF files are allowed.");
      return false;
    }

    if (file.size > maxSize) {
      toast.error("File size exceeds the 512 KB limit.");
      return false;
    }

    return true;
  };

  const updateFormData = (name, value) => {
    setFormData((prevState) => {
      const [step, field] = name.split(".");
      return {
        ...prevState,
        [step]: {
          ...prevState[step],
          [field]: value,
        },
      };
    });
  };

  const openPreview = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      toast.error("Preview is not available.");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    let hasErrors = false;

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object") {
        Object.keys(formData[key]).forEach((subKey) => {
          if (
            !formData[key][subKey] &&
            subKey !== "cancelledChequeURL" &&
            subKey !== "address" &&
            subKey !== "idProof" &&
            subKey !== "pan" &&
            subKey !== "gst"
          ) {
            errors[`${key}.${subKey}`] = true;
            hasErrors = true;
          }
        });
      } else if (!formData[key]) {
        errors[key] = true;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    try {
      const data = await registerSellerAPI(formData);
      toast(data.message);
      if (data.message === "Seller added successfully") {
        const token = data.token;
        Cookies.set("token", token);
        sellerRegister();
        navigate("/StoreDetails");
        setCurrentStep(0);
        setFormData({
          detailsOfProvider: {
            email: "",
            mobileNumber: "",
            Orgname: "",
            password: "",
            role: "OrganizationAdmin",
            accessStatus: true,
            isApprovedByAdmin: true,
          },
          KYCdetails: {
            providerName: "",
            registeredAdd: "",
            storeEmail: "",
            mobileNo: "",
            PANNo: "",
            GSTIN: "",
            FSSAINo: "",
          },
          KYCurl: {
            address: null,
            idProof: null,
            pan: null,
            gst: null,
          },
          bankDetails: {
            accountHolderName: "",
            accountNo: "",
            bankName: "",
            branchName: "",
            ifscCode: "",
            cancelledChequeURL: null,
          },
        });
        setFormErrors({});
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const nextStep = () => {
  //   setCurrentStep((prevStep) => prevStep + 1);
  // };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobileNumber);
  };

  const isValidPassword = (password) => {
    const lengthValid = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      lengthValid &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChar
    );
  };

  const nextStep = () => {
    const errors = {};
    let hasErrors = false;

    switch (currentStep) {
      case 0: {
        if (
          !formData.detailsOfProvider.email ||
          !isValidEmail(formData.detailsOfProvider.email)
        ) {
          errors["detailsOfProvider.email"] = "Please enter a valid email.";
          hasErrors = true;
        }

        if (
          !formData.detailsOfProvider.mobileNumber ||
          !isValidMobileNumber(formData.detailsOfProvider.mobileNumber)
        ) {
          errors["detailsOfProvider.mobileNumber"] =
            "Please enter a valid mobile number (10 digits).";
          hasErrors = true;
        }

        if (!formData.detailsOfProvider.Orgname) {
          errors["detailsOfProvider.Orgname"] =
            "Organization name is required.";
          hasErrors = true;
        }

        if (
          !formData.detailsOfProvider.password ||
          !isValidPassword(formData.detailsOfProvider.password)
        ) {
          errors["detailsOfProvider.password"] =
            "Password must be at least 8 characters long, including uppercase, lowercase, numbers, and special characters.";
          hasErrors = true;
        }
        break;
      }
      case 1: {
        if (!formData.KYCdetails.providerName) {
          errors["KYCdetails.providerName"] = "Provider name is required.";
          hasErrors = true;
        }

        if (!formData.KYCdetails.registeredAdd) {
          errors["KYCdetails.registeredAdd"] =
            "Registered address is required.";
          hasErrors = true;
        }

        if (
          !formData.KYCdetails.storeEmail ||
          !isValidEmail(formData.KYCdetails.storeEmail)
        ) {
          errors["KYCdetails.storeEmail"] = "Please enter a valid store email.";
          hasErrors = true;
        }

        if (
          !formData.KYCdetails.mobileNo ||
          !isValidMobileNumber(formData.KYCdetails.mobileNo)
        ) {
          errors["KYCdetails.mobileNo"] =
            "Please enter a valid mobile number (10 digits).";
          hasErrors = true;
        }

        if (!formData.KYCdetails.PANNo) {
          errors["KYCdetails.PANNo"] = "PAN number is required.";
          hasErrors = true;
        }

        if (!formData.KYCdetails.GSTIN) {
          errors["KYCdetails.GSTIN"] = "GSTIN is required.";
          hasErrors = true;
        }

        if (!formData.KYCdetails.FSSAINo) {
          errors["KYCdetails.FSSAINo"] = "FSSAI number is required.";
          hasErrors = true;
        }
        break;
      }
      case 2: {
        if (!formData.KYCurl.address) {
          errors["KYCurl.address"] = "Address proof is required.";
          hasErrors = true;
        }

        if (!formData.KYCurl.idProof) {
          errors["KYCurl.idProof"] = "ID proof is required.";
          hasErrors = true;
        }

        if (!formData.KYCurl.pan) {
          errors["KYCurl.pan"] = "PAN proof is required.";
          hasErrors = true;
        }

        if (!formData.KYCurl.gst) {
          errors["KYCurl.gst"] = "GST proof is required.";
          hasErrors = true;
        }
        break;
      }
      case 3: {
        if (!formData.bankDetails.accountHolderName) {
          errors["bankDetails.accountHolderName"] =
            "Account holder name is required.";
          hasErrors = true;
        }

        if (!formData.bankDetails.accountNo) {
          errors["bankDetails.accountNo"] = "Account number is required.";
          hasErrors = true;
        }

        if (!formData.bankDetails.bankName) {
          errors["bankDetails.bankName"] = "Bank name is required.";
          hasErrors = true;
        }

        if (!formData.bankDetails.branchName) {
          errors["bankDetails.branchName"] = "Branch name is required.";
          hasErrors = true;
        }

        if (!formData.bankDetails.ifscCode) {
          errors["bankDetails.ifscCode"] = "IFSC code is required.";
          hasErrors = true;
        }
        break;
      }
      default:
        break;
    }

    if (hasErrors) {
      setFormErrors(errors);
      toast.error("Please fill in all required fields.");
      return;
    }

    setFormErrors({});
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const isNextDisabled = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid =
          formData.detailsOfProvider.email &&
          formData.detailsOfProvider.email &&
          formData.detailsOfProvider.mobileNumber &&
          formData.detailsOfProvider.mobileNumber &&
          formData.detailsOfProvider.Orgname &&
          formData.detailsOfProvider.password;
        break;
      case 1:
        isValid =
          formData.KYCdetails.providerName &&
          formData.KYCdetails.registeredAdd &&
          formData.KYCdetails.storeEmail &&
          formData.KYCdetails.storeEmail &&
          formData.KYCdetails.mobileNo &&
          formData.KYCdetails.mobileNo &&
          formData.KYCdetails.PANNo &&
          formData.KYCdetails.GSTIN &&
          formData.KYCdetails.FSSAINo;
        break;
      case 2:
        isValid =
          formData.KYCurl.address &&
          formData.KYCurl.idProof &&
          formData.KYCurl.pan &&
          formData.KYCurl.gst;
        break;
      case 3:
        isValid =
          formData.bankDetails.accountHolderName &&
          formData.bankDetails.accountNo &&
          formData.bankDetails.bankName &&
          formData.bankDetails.branchName &&
          formData.bankDetails.ifscCode;
        break;
      default:
        break;
    }

    return !isValid;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5}>
        <Box sx={{ marginTop: "50px", display: { xs: "none", sm: "block" } }}>
          <img
            src={image}
            alt="loading...."
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} md={6}>
        <Box sx={{ width: "90%" ,marginLeft:"5%" }}>
          <Stepper
            activeStep={currentStep}
            alternativeLabel
            sx={{ marginTop: "20px" }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel
                  sx={{
                    fontWeight: "700",
                    color: "primary.main",
                    fontSize: "2rem",
                    textTransform: "uppercase",
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <form onSubmit={handleSubmit}>
            {currentStep === 0 && (
              <Grid container spacing={3} sx={{ marginTop: 5 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="detailsOfProvider.email"
                    value={formData.detailsOfProvider.email}
                    onChange={handleChange}
                    error={!!formErrors["detailsOfProvider.email"]}
                    helperText={formErrors["detailsOfProvider.email"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Mobile Number"
                    name="detailsOfProvider.mobileNumber"
                    value={formData.detailsOfProvider.mobileNumber}
                    onChange={handleChange}
                    error={!!formErrors["detailsOfProvider.mobileNumber"]}
                    helperText={formErrors["detailsOfProvider.mobileNumber"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Name"
                    name="detailsOfProvider.Orgname"
                    value={formData.detailsOfProvider.Orgname}
                    onChange={handleChange}
                    error={Boolean(formErrors["detailsOfProvider.Orgname"])}
                    helperText={
                      formErrors["detailsOfProvider.Orgname"] &&
                      "Name is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    name="detailsOfProvider.password"
                    value={formData.detailsOfProvider.password}
                    onChange={handleChange}
                    error={!!formErrors["detailsOfProvider.password"]}
                    helperText={formErrors["detailsOfProvider.password"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Role"
                    name="detailsOfProvider.role"
                    value={formData.detailsOfProvider.role}
                    onChange={handleChange}
                    error={Boolean(formErrors["detailsOfProvider.role"])}
                    helperText={
                      formErrors["detailsOfProvider.role"] && "Role is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Access Status"
                    name="detailsOfProvider.accessStatus"
                    value={formData.detailsOfProvider.accessStatus}
                    onChange={handleChange}
                    error={Boolean(
                      formErrors["detailsOfProvider.accessStatus"]
                    )}
                    helperText={
                      formErrors["detailsOfProvider.accessStatus"] &&
                      "Access Status is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Approval Status"
                    name="detailsOfProvider.isApprovedByAdmin"
                    value={formData.detailsOfProvider.isApprovedByAdmin}
                    onChange={handleChange}
                    error={Boolean(
                      formErrors["detailsOfProvider.isApprovedByAdmin"]
                    )}
                    helperText={
                      formErrors["detailsOfProvider.isApprovedByAdmin"] &&
                      "Approval Status is required"
                    }
                  />
                </Grid>
              </Grid>
            )}
            {currentStep === 1 && (
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Provider Name"
                    name="KYCdetails.providerName"
                    value={formData.KYCdetails.providerName}
                    onChange={handleChange}
                    error={Boolean(formErrors["KYCdetails.providerName"])}
                    helperText={
                      formErrors["KYCdetails.providerName"] &&
                      "Provider Name is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Registered Address"
                    name="KYCdetails.registeredAdd"
                    value={formData.KYCdetails.registeredAdd}
                    onChange={handleChange}
                    error={Boolean(formErrors["KYCdetails.registeredAdd"])}
                    helperText={
                      formErrors["KYCdetails.registeredAdd"] &&
                      "Registered Address is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Store Email"
                    name="KYCdetails.storeEmail"
                    type="email"
                    value={formData.KYCdetails.storeEmail}
                    onChange={handleChange}
                    error={!!formErrors["KYCdetails.storeEmail"]}
                    helperText={formErrors["KYCdetails.storeEmail"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Mobile Number"
                    name="KYCdetails.mobileNo"
                    value={formData.KYCdetails.mobileNo}
                    onChange={handleChange}
                    error={!!formErrors["KYCdetails.mobileNo"]}
                    helperText={formErrors["KYCdetails.mobileNo"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="PAN Number"
                    name="KYCdetails.PANNo"
                    value={formData.KYCdetails.PANNo}
                    onChange={handleChange}
                    error={Boolean(formErrors["KYCdetails.PANNo"])}
                    helperText={
                      formErrors["KYCdetails.PANNo"] && "PAN Number is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="GSTIN"
                    name="KYCdetails.GSTIN"
                    value={formData.KYCdetails.GSTIN}
                    onChange={handleChange}
                    error={Boolean(formErrors["KYCdetails.GSTIN"])}
                    helperText={
                      formErrors["KYCdetails.GSTIN"] && "GSTIN is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="FSSAI Number"
                    name="KYCdetails.FSSAINo"
                    value={formData.KYCdetails.FSSAINo}
                    onChange={handleChange}
                    error={Boolean(formErrors["KYCdetails.FSSAINo"])}
                    helperText={
                      formErrors["KYCdetails.FSSAINo"] &&
                      "FSSAI Number is required"
                    }
                  />
                </Grid>
              </Grid>
            )}
            {currentStep === 2 && (
              <Grid container spacing={8} sx={{ marginTop: 4 }}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <Input
                      accept="image/*"
                      type="file"
                      name="KYCurl.address"
                      onChange={handleChange}
                      sx={{ maxWidth: "300px" }}
                    />

                    {previewUrls.address && (
                      <Button
                        onClick={() => openPreview(previewUrls.address)}
                        variant="contained"
                      >
                        Preview
                      </Button>
                    )}
                  </Box>
                  <p style={{ margin: 0 }}>Upload Address Proof</p>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <Input
                      accept="image/*"
                      type="file"
                      name="KYCurl.idProof"
                      onChange={handleChange}
                      sx={{ maxWidth: "300px" }}
                    />

                    {previewUrls.idProof && (
                      <Button
                        onClick={() => openPreview(previewUrls.idProof)}
                        variant="contained"
                      >
                        Preview
                      </Button>
                    )}
                  </Box>
                  <p style={{ margin: 0 }}>Upload ID Proof</p>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <Input
                      accept="image/*"
                      type="file"
                      name="KYCurl.pan"
                      onChange={handleChange}
                      sx={{ maxWidth: "300px" }}
                    />

                    {previewUrls.pan && (
                      <Button
                        onClick={() => openPreview(previewUrls.pan)}
                        variant="contained"
                      >
                        Preview
                      </Button>
                    )}
                  </Box>
                  <p style={{ margin: 0 }}>Upload PAN Proof</p>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <Input
                      accept="image/*"
                      type="file"
                      name="KYCurl.gst"
                      onChange={handleChange}
                      sx={{ maxWidth: "300px" }}
                    />

                    {previewUrls.gst && (
                      <Button
                        onClick={() => openPreview(previewUrls.gst)}
                        variant="contained"
                      >
                        Preview
                      </Button>
                    )}
                  </Box>
                  <p style={{ margin: 0 }}>Upload GST Proof</p>
                </Grid>
              </Grid>
            )}
            {currentStep === 3 && (
              <Grid container spacing={4} sx={{ marginTop: 4 }}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Account Holder Name"
                    name="bankDetails.accountHolderName"
                    value={formData.bankDetails.accountHolderName}
                    onChange={handleChange}
                    error={Boolean(formErrors["bankDetails.accountHolderName"])}
                    helperText={
                      formErrors["bankDetails.accountHolderName"] &&
                      "Account Holder Name is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Account Number"
                    name="bankDetails.accountNo"
                    value={formData.bankDetails.accountNo}
                    onChange={handleChange}
                    error={Boolean(formErrors["bankDetails.accountNo"])}
                    helperText={
                      formErrors["bankDetails.accountNo"] &&
                      "Account Number is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Bank Name"
                    name="bankDetails.bankName"
                    value={formData.bankDetails.bankName}
                    onChange={handleChange}
                    error={Boolean(formErrors["bankDetails.bankName"])}
                    helperText={
                      formErrors["bankDetails.bankName"] &&
                      "Bank Name is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Branch Name"
                    name="bankDetails.branchName"
                    value={formData.bankDetails.branchName}
                    onChange={handleChange}
                    error={Boolean(formErrors["bankDetails.branchName"])}
                    helperText={
                      formErrors["bankDetails.branchName"] &&
                      "Branch Name is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="IFSC Code"
                    name="bankDetails.ifscCode"
                    value={formData.bankDetails.ifscCode}
                    onChange={handleChange}
                    error={Boolean(formErrors["bankDetails.ifscCode"])}
                    helperText={
                      formErrors["bankDetails.ifscCode"] &&
                      "IFSC Code is required"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <Input
                      accept="image/*"
                      type="file"
                      name="bankDetails.cancelledChequeURL"
                      onChange={handleChange}
                      sx={{ maxWidth: "300px" }}
                    />

                    {previewUrls.cancelledChequeURL && (
                      <Button
                        onClick={() =>
                          openPreview(previewUrls.cancelledChequeURL)
                        }
                        variant="contained"
                      >
                        Preview
                      </Button>
                    )}
                  </Box>
                  <p style={{ margin: 0 }}>Upload Cancelled Cheque</p>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                {currentStep > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={6} textAlign="right">
                {currentStep < steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={nextStep}
                    disabled={isNextDisabled()} // Disable if validation fails
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="warning" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};
export default MultiStepForm;

// import {
//   Button,
//   Grid,
//   TextField,
//   Stepper,
//   Step,
//   StepLabel,
//   Input,
//   CircularProgress,
//   Box,
// } from "@mui/material";
// import { toast } from "react-toastify";
// import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../store/AuthContext";
// import image from "../../img/backgroundimg.png";

// const steps = [
//   "Personal Information",
//   "KYC Information",
//   "Upload Documents",
//   "Bank Details",
// ];

// const MultiStepForm = () => {
//   const navigate = useNavigate();
//   const { sellerRegister } = useAuth();
//   // const api = process.env.REACT_APP_API;

//   const [currentStep, setCurrentStep] = useState(0);
//   const [formData, setFormData] = useState({
//     detailsOfProvider: {
//       email: "",
//       mobileNumber: "",
//       Orgname: "",
//       password: "",
//       role: "OrganizationAdmin",
//       accessStatus: true,
//       isApprovedByAdmin: true,
//     },
//     KYCdetails: {
//       providerName: "",
//       registeredAdd: "",
//       storeEmail: "",
//       mobileNo: "",
//       PANNo: "",
//       GSTIN: "",
//       FSSAINo: "",
//     },
//     KYCurl: {
//       address: null,
//       idProof: null,
//       pan: null,
//       gst: null,
//     },
//     bankDetails: {
//       accountHolderName: "",
//       accountNo: "",
//       bankName: "",
//       branchName: "",
//       ifscCode: "",
//       cancelledChequeURL: null,
//     },
//   });

//   const [formErrors, setFormErrors] = useState({});
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value, type, files } = e.target;

//     if (type === "file") {
//       const fileName = files[0] ? files[0].name : null;

//       setFormData((prevState) => {
//         const [step, field] = name.split(".");
//         return {
//           ...prevState,
//           [step]: {
//             ...prevState[step],
//             [field]: fileName,
//           },
//         };
//       });
//     } else {
//       setFormData((prevState) => {
//         const [step, field] = name.split(".");
//         return {
//           ...prevState,
//           [step]: {
//             ...prevState[step],
//             [field]: value,
//           },
//         };
//       });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const errors = {};
//     let hasErrors = false;

//     Object.keys(formData).forEach((key) => {
//       if (typeof formData[key] === "object") {
//         Object.keys(formData[key]).forEach((subKey) => {
//           if (
//             !formData[key][subKey] &&
//             subKey !== "cancelledChequeURL" &&
//             subKey !== "address" &&
//             subKey !== "idProof" &&
//             subKey !== "pan" &&
//             subKey !== "gst"
//           ) {
//             errors[`${key}.${subKey}`] = true;
//             hasErrors = true;
//           }
//         });
//       } else if (!formData[key]) {
//         errors[key] = true;
//         hasErrors = true;
//       }
//     });

//     if (hasErrors) {
//       setFormErrors(errors);
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`http://localhost:8080/dashboard/adminRegister`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });
//       const data = await response.json();
//       toast(data.message);
//       if (data.message === "Seller added successfully") {
//         const token = data.token;
//         Cookies.set("token", token);
//         sellerRegister();
//         navigate("/StoreDetails");
//         setCurrentStep(0);
//         setFormData({
//           detailsOfProvider: {
//             email: "",
//             mobileNumber: "",
//             Orgname: "",
//             password: "",
//             role: "OrganizationAdmin",
//             accessStatus: true,
//             isApprovedByAdmin: true,
//           },
//           KYCdetails: {
//             providerName: "",
//             registeredAdd: "",
//             storeEmail: "",
//             mobileNo: "",
//             PANNo: "",
//             GSTIN: "",
//             FSSAINo: "",
//           },
//           KYCurl: {
//             address: null,
//             idProof: null,
//             pan: null,
//             gst: null,
//           },
//           bankDetails: {
//             accountHolderName: "",
//             accountNo: "",
//             bankName: "",
//             branchName: "",
//             ifscCode: "",
//             cancelledChequeURL: null,
//           },
//         });
//         setFormErrors({});
//       } else {
//         console.log("Something went wrong");
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const nextStep = () => {
//     setCurrentStep((prevStep) => prevStep + 1);
//   };

//   const prevStep = () => {
//     setCurrentStep((prevStep) => prevStep - 1);
//   };

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={5}>
//         <Box sx={{marginTop:"50px"}}>
//           <img src={image} alt="loading...." height="100%" />
//         </Box>
//       </Grid>

//         <Grid item xs={7}>
//           <Box sx={{width:"90%"}}>
//             <Stepper
//               activeStep={currentStep}
//               alternativeLabel
//               sx={{ marginTop: "20px" }}
//             >
//               {steps.map((label, index) => (
//                 <Step key={index}>
//                   <StepLabel
//                     sx={{
//                       fontWeight: "700",
//                       color: "primary.main",
//                       fontSize: "2rem",
//                       textTransform: "uppercase",
//                     }}
//                   >
//                     {label}
//                   </StepLabel>
//                 </Step>
//               ))}
//             </Stepper>
//             <form onSubmit={handleSubmit}>
//               {currentStep === 0 && (
//                 <Grid container spacing={3} sx={{ marginTop: 5 }}>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Email"
//                       name="detailsOfProvider.email"
//                       value={formData.detailsOfProvider.email}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["detailsOfProvider.email"])}
//                       helperText={
//                         formErrors["detailsOfProvider.email"] &&
//                         "Email is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Mobile Number"
//                       name="detailsOfProvider.mobileNumber"
//                       value={formData.detailsOfProvider.mobileNumber}
//                       onChange={handleChange}
//                       error={Boolean(
//                         formErrors["detailsOfProvider.mobileNumber"]
//                       )}
//                       helperText={
//                         formErrors["detailsOfProvider.mobileNumber"] &&
//                         "Mobile Number is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Name"
//                       name="detailsOfProvider.Orgname"
//                       value={formData.detailsOfProvider.Orgname}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["detailsOfProvider.Orgname"])}
//                       helperText={
//                         formErrors["detailsOfProvider.Orgname"] &&
//                         "Name is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Password"
//                       type="password"
//                       name="detailsOfProvider.password"
//                       value={formData.detailsOfProvider.password}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["detailsOfProvider.password"])}
//                       helperText={
//                         formErrors["detailsOfProvider.password"] &&
//                         "Password is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Role"
//                       name="detailsOfProvider.role"
//                       value={formData.detailsOfProvider.role}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["detailsOfProvider.role"])}
//                       helperText={
//                         formErrors["detailsOfProvider.role"] &&
//                         "Role is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Access Status"
//                       name="detailsOfProvider.accessStatus"
//                       value={formData.detailsOfProvider.accessStatus}
//                       onChange={handleChange}
//                       error={Boolean(
//                         formErrors["detailsOfProvider.accessStatus"]
//                       )}
//                       helperText={
//                         formErrors["detailsOfProvider.accessStatus"] &&
//                         "Access Status is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Approval Status"
//                       name="detailsOfProvider.isApprovedByAdmin"
//                       value={formData.detailsOfProvider.isApprovedByAdmin}
//                       onChange={handleChange}
//                       error={Boolean(
//                         formErrors["detailsOfProvider.isApprovedByAdmin"]
//                       )}
//                       helperText={
//                         formErrors["detailsOfProvider.isApprovedByAdmin"] &&
//                         "Approval Status is required"
//                       }
//                     />
//                   </Grid>
//                 </Grid>
//               )}
//               {currentStep === 1 && (
//                 <Grid container spacing={2} sx={{ marginTop: 2 }}>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Provider Name"
//                       name="KYCdetails.providerName"
//                       value={formData.KYCdetails.providerName}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.providerName"])}
//                       helperText={
//                         formErrors["KYCdetails.providerName"] &&
//                         "Provider Name is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Registered Address"
//                       name="KYCdetails.registeredAdd"
//                       value={formData.KYCdetails.registeredAdd}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.registeredAdd"])}
//                       helperText={
//                         formErrors["KYCdetails.registeredAdd"] &&
//                         "Registered Address is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Store Email"
//                       name="KYCdetails.storeEmail"
//                       value={formData.KYCdetails.storeEmail}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.storeEmail"])}
//                       helperText={
//                         formErrors["KYCdetails.storeEmail"] &&
//                         "Store Email is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Mobile Number"
//                       name="KYCdetails.mobileNo"
//                       value={formData.KYCdetails.mobileNo}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.mobileNo"])}
//                       helperText={
//                         formErrors["KYCdetails.mobileNo"] &&
//                         "Mobile Number is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="PAN Number"
//                       name="KYCdetails.PANNo"
//                       value={formData.KYCdetails.PANNo}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.PANNo"])}
//                       helperText={
//                         formErrors["KYCdetails.PANNo"] &&
//                         "PAN Number is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="GSTIN"
//                       name="KYCdetails.GSTIN"
//                       value={formData.KYCdetails.GSTIN}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.GSTIN"])}
//                       helperText={
//                         formErrors["KYCdetails.GSTIN"] && "GSTIN is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="FSSAI Number"
//                       name="KYCdetails.FSSAINo"
//                       value={formData.KYCdetails.FSSAINo}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["KYCdetails.FSSAINo"])}
//                       helperText={
//                         formErrors["KYCdetails.FSSAINo"] &&
//                         "FSSAI Number is required"
//                       }
//                     />
//                   </Grid>
//                 </Grid>
//               )}
//               {currentStep === 2 && (
//                 <Grid container spacing={8} sx={{ marginTop: 4 }}>
//                   <Grid item xs={12} >
//                     <Input
//                       fullWidth
//                       accept="image/*"
//                       type="file"
//                       name="KYCurl.address"
//                       onChange={handleChange}
//                     />
//                     <p>Upload Address Proof</p>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Input
//                      fullWidth
//                       accept="image/*"
//                       type="file"
//                       name="KYCurl.idProof"
//                       onChange={handleChange}
//                     />
//                     <p>Upload ID Proof</p>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Input
//                      fullWidth
//                       accept="image/*"
//                       type="file"
//                       name="KYCurl.pan"
//                       onChange={handleChange}
//                     />
//                     <p>Upload PAN Proof</p>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Input
//                      fullWidth
//                       accept="image/*"
//                       type="file"
//                       name="KYCurl.gst"
//                       onChange={handleChange}
//                     />
//                     <p>Upload GST Proof</p>
//                   </Grid>
//                 </Grid>
//               )}
//               {currentStep === 3 && (
//                 <Grid container spacing={4} sx={{ marginTop: 4 }}>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Account Holder Name"
//                       name="bankDetails.accountHolderName"
//                       value={formData.bankDetails.accountHolderName}
//                       onChange={handleChange}
//                       error={Boolean(
//                         formErrors["bankDetails.accountHolderName"]
//                       )}
//                       helperText={
//                         formErrors["bankDetails.accountHolderName"] &&
//                         "Account Holder Name is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Account Number"
//                       name="bankDetails.accountNo"
//                       value={formData.bankDetails.accountNo}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["bankDetails.accountNo"])}
//                       helperText={
//                         formErrors["bankDetails.accountNo"] &&
//                         "Account Number is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Bank Name"
//                       name="bankDetails.bankName"
//                       value={formData.bankDetails.bankName}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["bankDetails.bankName"])}
//                       helperText={
//                         formErrors["bankDetails.bankName"] &&
//                         "Bank Name is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="Branch Name"
//                       name="bankDetails.branchName"
//                       value={formData.bankDetails.branchName}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["bankDetails.branchName"])}
//                       helperText={
//                         formErrors["bankDetails.branchName"] &&
//                         "Branch Name is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       required
//                       fullWidth
//                       label="IFSC Code"
//                       name="bankDetails.ifscCode"
//                       value={formData.bankDetails.ifscCode}
//                       onChange={handleChange}
//                       error={Boolean(formErrors["bankDetails.ifscCode"])}
//                       helperText={
//                         formErrors["bankDetails.ifscCode"] &&
//                         "IFSC Code is required"
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Input
//                      fullWidth
//                       accept="image/*"
//                       type="file"
//                       name="bankDetails.cancelledChequeURL"
//                       onChange={handleChange}
//                     />
//                     <p>Upload Cancelled Cheque</p>
//                   </Grid>
//                 </Grid>
//               )}
//               <Grid container spacing={2} sx={{ marginTop: 4 }}>
//                 <Grid item xs={6}>
//                   {currentStep > 0 && (
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={prevStep}
//                     >
//                       Back
//                     </Button>
//                   )}
//                 </Grid>
//                 <Grid item xs={6} textAlign="right">
//                   {currentStep < steps.length - 1 ? (
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={nextStep}
//                     >
//                       Next
//                     </Button>
//                   ) : (
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       type="submit"
//                       disabled={loading}
//                     >
//                       {loading ? (
//                         <CircularProgress size={24} color="warning" />
//                       ) : (
//                         "Submit"
//                       )}
//                     </Button>
//                   )}
//                 </Grid>
//               </Grid>
//             </form>
//           </Box>
//         </Grid>

//     </Grid>
//   );
// };

// export default MultiStepForm;

// export default MultiStepForm;

// import React, { useState } from "react";
// import {
//   Button,
//   Container,
//   Grid,
//   TextField,
//   Stepper,
//   Step,
//   StepLabel,
//   Input,
//   CircularProgress,
// } from "@mui/material";
// import { toast } from "react-toastify";
// import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../store/AuthContext";

// const steps = [
//   "Personal Information",
//   "KYC Information",
//   "Upload Documents",
//   "Bank Details",
// ];

// const MultiStepForm = () => {
//   const navigate = useNavigate();
//   const { sellerRegister } = useAuth();
//   // const api = process.env.REACT_APP_API;

//   const [currentStep, setCurrentStep] = useState(0);
//   const [formData, setFormData] = useState({
//     detailsOfProvider: {
//       email: "",
//       mobileNumber: "",
//       Orgname: "",
//       password: "",
//       role: "OrganizationAdmin",
//       accessStatus: true,
//       isApprovedByAdmin: true,
//     },
//     KYCdetails: {
//       providerName: "",
//       registeredAdd: "",
//       storeEmail: "",
//       mobileNo: "",
//       PANNo: "",
//       GSTIN: "",
//       FSSAINo: "",
//     },
//     KYCurl: {
//       address: null,
//       idProof: null,
//       pan: null,
//       gst: null,
//     },
//     bankDetails: {
//       accountHolderName: "",
//       accountNo: "",
//       bankName: "",
//       branchName: "",
//       ifscCode: "",
//       cancelledChequeURL: null,
//     },
//   });

//   const [formErrors, setFormErrors] = useState({});
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value, type, files } = e.target;

//     if (type === "file") {
//       const fileName = files[0] ? files[0].name : null;

//       setFormData((prevState) => {
//         const [step, field] = name.split(".");
//         return {
//           ...prevState,
//           [step]: {
//             ...prevState[step],
//             [field]: fileName,
//           },
//         };
//       });
//     } else {
//       setFormData((prevState) => {
//         const [step, field] = name.split(".");
//         return {
//           ...prevState,
//           [step]: {
//             ...prevState[step],
//             [field]: value,
//           },
//         };
//       });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const errors = {};
//     let hasErrors = false;

//     Object.keys(formData).forEach((key) => {
//       if (typeof formData[key] === "object") {
//         Object.keys(formData[key]).forEach((subKey) => {
//           if (
//             !formData[key][subKey] &&
//             subKey !== "cancelledChequeURL" &&
//             subKey !== "address" &&
//             subKey !== "idProof" &&
//             subKey !== "pan" &&
//             subKey !== "gst"
//           ) {
//             errors[`${key}.${subKey}`] = true;
//             hasErrors = true;
//           }
//         });
//       } else if (!formData[key]) {
//         errors[key] = true;
//         hasErrors = true;
//       }
//     });

//     if (hasErrors) {
//       setFormErrors(errors);
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`http://localhost:8080/dashboard/adminRegister`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });
//       const data = await response.json();
//       toast(data.message);
//       if (data.message === "Seller added successfully") {
//         const token = data.token;
//         Cookies.set("token", token);
//         sellerRegister();
//         navigate("/StoreDetails");
//         setCurrentStep(0);
//         setFormData({
//           detailsOfProvider: {
//             email: "",
//             mobileNumber: "",
//             Orgname: "",
//             password: "",
//             role: "OrganizationAdmin",
//             accessStatus: true,
//             isApprovedByAdmin: true,
//           },
//           KYCdetails: {
//             providerName: "",
//             registeredAdd: "",
//             storeEmail: "",
//             mobileNo: "",
//             PANNo: "",
//             GSTIN: "",
//             FSSAINo: "",
//           },
//           KYCurl: {
//             address: null,
//             idProof: null,
//             pan: null,
//             gst: null,
//           },
//           bankDetails: {
//             accountHolderName: "",
//             accountNo: "",
//             bankName: "",
//             branchName: "",
//             ifscCode: "",
//             cancelledChequeURL: null,
//           },
//         });
//         setFormErrors({});
//       } else {
//         console.log("Something went wrong");
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const nextStep = () => {
//     setCurrentStep((prevStep) => prevStep + 1);
//   };

//   const prevStep = () => {
//     setCurrentStep((prevStep) => prevStep - 1);
//   };

//   return (
//     <Container maxWidth="md">
//       <Stepper activeStep={currentStep} alternativeLabel>
//         {steps.map((label, index) => (
//           <Step key={index}>
//             <StepLabel
//               sx={{
//                 fontWeight: "700",
//                 color: "primary.main",
//                 fontSize: "2rem",
//                 textTransform: "uppercase",
//               }}
//             >
//               {label}
//             </StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       <form onSubmit={handleSubmit}>
//         {currentStep === 0 && (
//           <Grid container spacing={1} sx={{ marginTop: 4 }}>
//             <Grid item xs={12}>
//               <TextField
//                 sx={{ fontFamily: "lato" }}
//                 required
//                 fullWidth
//                 label="Email"
//                 name="detailsOfProvider.email"
//                 value={formData.detailsOfProvider.email}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.email"])}
//                 helperText={
//                   formErrors["detailsOfProvider.email"] && "Email is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Mobile Number"
//                 name="detailsOfProvider.mobileNumber"
//                 value={formData.detailsOfProvider.mobileNumber}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.mobileNumber"])}
//                 helperText={
//                   formErrors["detailsOfProvider.mobileNumber"] &&
//                   "Mobile Number is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Name"
//                 name="detailsOfProvider.Orgname"
//                 value={formData.detailsOfProvider.Orgname}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.Orgname"])}
//                 helperText={
//                   formErrors["detailsOfProvider.Orgname"] && "Name is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Password"
//                 type="password"
//                 name="detailsOfProvider.password"
//                 value={formData.detailsOfProvider.password}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.password"])}
//                 helperText={
//                   formErrors["detailsOfProvider.password"] &&
//                   "Password is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Role"
//                 name="detailsOfProvider.role"
//                 value={formData.detailsOfProvider.role}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.role"])}
//                 helperText={
//                   formErrors["detailsOfProvider.role"] && "Role is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Access Status"
//                 name="detailsOfProvider.accessStatus"
//                 value={formData.detailsOfProvider.accessStatus}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.accessStatus"])}
//                 helperText={
//                   formErrors["detailsOfProvider.accessStatus"] &&
//                   "Access Status is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Approval Status"
//                 name="detailsOfProvider.isApprovedByAdmin"
//                 value={formData.detailsOfProvider.isApprovedByAdmin}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["detailsOfProvider.isApprovedByAdmin"])}
//                 helperText={
//                   formErrors["detailsOfProvider.isApprovedByAdmin"] &&
//                   "Approval Status is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//           </Grid>
//         )}
//         {currentStep === 1 && (
//           <Grid container spacing={2} sx={{ marginTop: 2 }}>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Provider Name"
//                 name="KYCdetails.providerName"
//                 value={formData.KYCdetails.providerName}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.providerName"])}
//                 helperText={
//                   formErrors["KYCdetails.providerName"] && "Provider Name is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Registered Address"
//                 name="KYCdetails.registeredAdd"
//                 value={formData.KYCdetails.registeredAdd}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.registeredAdd"])}
//                 helperText={
//                   formErrors["KYCdetails.registeredAdd"] && "Registered Address is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Store Email"
//                 name="KYCdetails.storeEmail"
//                 value={formData.KYCdetails.storeEmail}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.storeEmail"])}
//                 helperText={
//                   formErrors["KYCdetails.storeEmail"] && "Store Email is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Mobile Number"
//                 name="KYCdetails.mobileNo"
//                 value={formData.KYCdetails.mobileNo}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.mobileNo"])}
//                 helperText={
//                   formErrors["KYCdetails.mobileNo"] && "Mobile Number is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="PAN Number"
//                 name="KYCdetails.PANNo"
//                 value={formData.KYCdetails.PANNo}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.PANNo"])}
//                 helperText={
//                   formErrors["KYCdetails.PANNo"] && "PAN Number is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="GSTIN"
//                 name="KYCdetails.GSTIN"
//                 value={formData.KYCdetails.GSTIN}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.GSTIN"])}
//                 helperText={
//                   formErrors["KYCdetails.GSTIN"] && "GSTIN is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="FSSAI Number"
//                 name="KYCdetails.FSSAINo"
//                 value={formData.KYCdetails.FSSAINo}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["KYCdetails.FSSAINo"])}
//                 helperText={
//                   formErrors["KYCdetails.FSSAINo"] && "FSSAI Number is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//           </Grid>
//         )}
//         {currentStep === 2 && (
//           <Grid container spacing={8} sx={{ marginTop: 4 }}>
//             <Grid item xs={12}>
//             <p>Address Proof</p>
//               <Input
//                 type="file"
//                 name="KYCurl.address"
//                 onChange={handleChange}
//                 fullWidth
//               />
//               {formData.KYCurl.address && <p>{formData.KYCurl.address}</p>}
//             </Grid>

//             <Grid item xs={12}>
//             <p>ID Proof </p>
//               <Input
//                 type="file"
//                 name="KYCurl.idProof"
//                 onChange={handleChange}
//                 fullWidth
//               />
//               {formData.KYCurl.idProof && <p>{formData.KYCurl.idProof}</p>}
//             </Grid>
//             <Grid item xs={12}>
//             <p>PAN Card Proof</p>

//               <Input
//                 type="file"
//                 name="KYCurl.pan"
//                 onChange={handleChange}
//                 fullWidth
//               />
//               {formData.KYCurl.pan && <p>{formData.KYCurl.pan}</p>}
//             </Grid>
//             <Grid item xs={12}>
//             <p>GSTIN Proof</p>

//               <Input
//                 type="file"
//                 name="KYCurl.gst"
//                 onChange={handleChange}
//                 fullWidth
//               />
//               {formData.KYCurl.gst && <p>{formData.KYCurl.gst}</p>}
//             </Grid>
//           </Grid>
//         )}
//         {currentStep === 3 && (
//           <Grid container spacing={4} sx={{ marginTop: 4 }}>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Account Holder Name"
//                 name="bankDetails.accountHolderName"
//                 value={formData.bankDetails.accountHolderName}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["bankDetails.accountHolderName"])}
//                 helperText={
//                   formErrors["bankDetails.accountHolderName"] &&
//                   "Account Holder Name is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Account Number"
//                 name="bankDetails.accountNo"
//                 value={formData.bankDetails.accountNo}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["bankDetails.accountNo"])}
//                 helperText={
//                   formErrors["bankDetails.accountNo"] && "Account Number is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Bank Name"
//                 name="bankDetails.bankName"
//                 value={formData.bankDetails.bankName}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["bankDetails.bankName"])}
//                 helperText={
//                   formErrors["bankDetails.bankName"] && "Bank Name is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Branch Name"
//                 name="bankDetails.branchName"
//                 value={formData.bankDetails.branchName}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["bankDetails.branchName"])}
//                 helperText={
//                   formErrors["bankDetails.branchName"] && "Branch Name is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 label="IFSC Code"
//                 name="bankDetails.ifscCode"
//                 value={formData.bankDetails.ifscCode}
//                 onChange={handleChange}
//                 error={Boolean(formErrors["bankDetails.ifscCode"])}
//                 helperText={
//                   formErrors["bankDetails.ifscCode"] && "IFSC Code is required"
//                 }
//                 inputProps={{ style: { fontFamily: "lato" } }}
//                 InputLabelProps={{ style: { fontFamily: "lato" } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Input
//                 type="file"
//                 name="bankDetails.cancelledChequeURL"
//                 onChange={handleChange}
//                 fullWidth
//               />
//               {formData.bankDetails.cancelledChequeURL && <p>{formData.bankDetails.cancelledChequeURL}</p>}
//             </Grid>
//           </Grid>
//         )}
//         <Grid container spacing={2} sx={{ marginTop: 4 }}>
//           <Grid item xs={6}>
//             {currentStep > 0 && (
//               <Button variant="contained" color="primary" onClick={prevStep}>
//                 Back
//               </Button>
//             )}
//           </Grid>
//           <Grid item xs={6} textAlign="right">
//             {currentStep < steps.length - 1 ? (
//               <Button variant="contained" color="primary" onClick={nextStep}>
//                 Next
//               </Button>
//             ) : (
//               <Button
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//                 disabled={loading}
//               >
//                 {loading ? <CircularProgress size={24} color="warning" /> : "Submit"}
//               </Button>
//             )}
//           </Grid>
//         </Grid>
//       </form>
//     </Container>
//   );
// };

// export default MultiStepForm;
